import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetStudentsFetchData, GetCohortsFetchData, SendInvitation, UploadStudentsFetchData,CopyEmailAsPkData, reduxUpdateInformation, DeleteStudentsData} from '../../redux/ManageStudents/actions';
import {PreviewStudentPhoto} from '../../redux/ManageCertificate/actions';
import {GetProfileFetchData} from '../../redux/Profile/actions';
import "./style.css";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import Select from "react-dropdown-select";
import * as XLSX from 'xlsx';
import {getStudentsInputData, updateStudentsInputData, uploadstudentbyapi, getProfile, copyemailaspkurl, UPLOADSTUDENTS,UPLOADPHOTOS, UPLOADMULTIPHOTOS, getStudents,getCohorts,sendInvitation,server_ip, updateinformationurl, previewstudentphoto, deletestudent} from "../../utils/constants";
import {Alert,Button, Modal} from 'react-bootstrap';
import { Markup } from 'interweave';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import axios from 'axios';
import ApiDialog from './apidialog'; // Adjust the import path as necessary
// import { deleteStudentsData } from '../../redux/ManageStudents/reducer';

class VerificationComponent extends Component {
    checkStats = {};
    Cohorts = [];
    CertType=[{"label":"Degree","value":"degree"},{"label":"Migration","value":"migration"},{"label":"Transfer","value":"transfer"},{"label":"Awards","value":"awards"},{"label":"Relieving","value":"relieving"},{"label":"Bonafide","value":"bonafide"},{"label":"Medal","value":"medal"},{"label":"Transcript","value":"transcript"},{"label":"Semester-wise Grade card","value":"semesterwisegradecard"},{"label":"OpenBadges","value":"openbadges"},{"label":"OR","value":"or"}];
    selectedCohort = '';
    selectedCertType='';
    files = [];
    photofiles = [];
    pageCount = 1;
    mainPageCount = 1;
    mainPageOffset=0;
    initialMainPageIndex=0;
    maxitemsperpage=50;
    cohortselectindex=0;
    certtypeselectindex=0;
    publicnewmeail="";
    isapimode=false;
    ipAddress = "";
    constructor(props) {
        super(props);
        this.state={
            con: true,
            dialogOpen: false,
            InvitationStatus: false,
            placeholder: "Choose File",
            Cohorts: [],
            Students: [],
            loaded: true,
            StudentsData: [],
            UploadStudents:[],
            alertData: {
                status: false,
                className: '',
                message: ''
            },
            showModal: false,
            showUpdateInformation:false,
            showDeclineConfirmModal:false,
            deleteStudentConfirmModal: false,
            newemail:"",
            newfirstname:"",
            newmiddlename:"",
            newlastname:"",
            newstudentid: "",
            newfacultyname: "",
            newfathername: "",
            newmothername: "",
            newphonenumber: "",
            newsecondemailaddress: "",
            newserialnumber: "",
            newspecializationone: "",
            newspecializationtwo: "",
            newcompetencyname: "",
            newcoursename: "",
            selectedcoid:"",
            selectedstdid:"",
            ModalContents:[],
            currentOffset : 0,
            searchValue:"",
            displayCohort: [],
            selectedphotoContent: "",
            selectedPhotoTitle: "",
            showstudentname: "",
            showstudentid: "",
            showemail: "",
            showfathername: "",
            showcoursename: "",
            showexamyear: "",
            showschool: "",
            showcerttype: "",
            showdocumentid: "",
            showimagedata: "",
            verificationitems:""
        }
        this.GetStudents = this.GetStudents.bind(this);
        this.changeCohorts = this.changeCohorts.bind(this);
        //this.GetCohorts = this.GetCohorts.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeUpdateInformation = this.closeUpdateInformation.bind(this);
        this.closeDeleteStudentConfirmModal = this.closeDeleteStudentConfirmModal.bind(this);
        this.updateInformation = this.updateInformation.bind(this);
        this.delete_student = this.delete_student.bind(this);
        this.deleteStudentConfirm = this.deleteStudentConfirm.bind(this);        
        localStorage.setItem("selectedpageindex","0");  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;   
        this.selectPageSize = this.selectPageSize.bind(this);        

        this.closeDeclineConfirmModal = this.closeDeclineConfirmModal.bind(this);        
        this.showDeclineConfirmModal = this.showDeclineConfirmModal.bind(this);        
        //initialize ms        
       
    }
    setNewEmail(pemail)
    {
        this.setState({"newemail":pemail});
    }
    setFirstName(name)
    {
        this.setState({"newfirstname":name});
    }
    setMiddleName(name)
    {
        this.setState({"newmiddlename":name});
    }
    setLastName(name)
    {
        this.setState({"newlastname":name});
    }
    setStudentInfo(keyword, value){
        this.setState({keyword: value});
    }
    selectPageSize = (e) => {
        let idx = e.target.selectedIndex;        
        let value = e.target.options[idx].value; 
        this.maxitemsperpage=value;
        

        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }
    updatestudents(action) {
        console.log("action=", action)
        try {
            
            this.setState({showDeclineConfirmModal:false});        
            let selectedItems = [];
            let issuedItems = []
            if ( this.checkStats["checkStats"].length > 1 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if (action == "approve"){
                        if ( this.checkStats["checkStats"][i].checked && (this.checkStats["checkStats"][i].status == "3" || this.checkStats["checkStats"][i].status == "2")) {
                            issuedItems.push(this.checkStats["checkStats"][i].id);
                        }
                    }
                    else if (action == "decline"){
                        if ( this.checkStats["checkStats"][i].checked && this.checkStats["checkStats"][i].status == "3") {
                            issuedItems.push(this.checkStats["checkStats"][i].id);
                        }
                    }
                    
                    if (this.checkStats["checkStats"][i].checked) selectedItems.push(this.checkStats["checkStats"][i].id);
                }
                //console.log('this.checkStats["checkStats"], selectedItems, issuedItems=', this.checkStats["checkStats"], selectedItems, issuedItems);
                if ( selectedItems.length === 0 ) {            
                    this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students.'}})
                    this.setState({con:false});
                } 
                else if (issuedItems.length === 0){
                    this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select issued students.'}})
                    this.setState({con:false});
                }
                else {
                    this.closeAlert();
                    this.setState({con:true});
                    var Token = 'bearer ' +localStorage.getItem('Token');
                    const headers = {
                    'Authorization':  Token,                
                    }

                   
                    let payload = {
                        action: action,                       
                        selectedItems : issuedItems,
                        certtype : this.selectedCertType,
                    };
                    if (action == "decline"){
                        let declinereason= document.getElementById("declinereason").value;
                        if(declinereason==null || declinereason==undefined) declinereason="";
                        payload = {
                            action: action,
                            declinereason:declinereason,
                            selectedItems : issuedItems,
                            certtype : this.selectedCertType,
                        };
                    }
                    // this.props.dispatch(CopyEmailAsPkData(copyemailaspkurl, JSON.stringify(payload)));
    
                    axios.post(updateStudentsInputData, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
                    }).then(response => {             
                        if(response.status == "200"){    
                            
                            this.setState({alertData:{status:'Success',className: 'success', message: response.data.message}});
                            
                            this.GetStudentsInputData(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
                        }
                        else if (response.status == "400"){
                           
                            this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
                        }
                        
                    }).catch((error) => {
                        this.setState({con:false});
                        this.setState({alertData:{status:'empty',className: 'warning', message: "Error"}});
                    });    
                }  
            } 
            else
            {
                this.setState({con:false});
            }           
              
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
    }

    initCheckStatsinprogress(argstudents) {
        let data = [];
        for (let index = 0; index < argstudents.length; index++) {
            const parent = argstudents[index].data;
            let subItems = [];
            const children = argstudents[index].children;
            if(children !== null && children !== undefined && children.length > 0) {
                for (let i = 0; i < children.length; i++) {
                    const child = children[i].data;
                    let idval=child.cohortid+"="+child.studentid;
                    let stdstatus = child.field5;
                    data.push({id: idval, checked: false, status: stdstatus});
                }
            }
            let idval=parent.cohortid+"="+parent.studentid;
            data.push({id: idval, checked: false});
        }
        this.checkStats["checkStats"]=data;
    }
    GetStudents (cohortId,pageOffset,searchquery,certtype="degree") {
        
        if(cohortId=="") return;
        this.setState({ con: true });
        //this.setState({loaded: false});
        
        if(certtype==""||certtype==null) certtype="degree";
        
        let payload = {
            key : "cohort",
            value: cohortId,
            certtypevalue:certtype,
            pageOffset:pageOffset,
            maxitemsperpage:this.maxitemsperpage,
            searchValue:searchquery,
            step: "all"
        };
        this.props.dispatch(GetStudentsFetchData(getStudents, payload));
    }
    
    changeCohorts = (e) => {
        let idx = e.target.selectedIndex;
        this.cohortselectindex=idx;
        let value = e.target.options[idx].value; 
        this.selectedCohort=value;
        this.setState({size: 1});
        e.target.blur();
        localStorage.setItem('selectedCohort', this.selectedCohort);
        

        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }

    setCohort = displayCohort => {
        if(displayCohort.length != 0){
            this.selectedCohort = displayCohort[0].value;
            this.setState({size: 1});
            localStorage.setItem('selectedCohort', this.selectedCohort);

            localStorage.setItem('selectedpageindex',0);  
            this.mainPageOffset=0;
            this.initialMainPageIndex=0;       
            this.GetStudentsInputData(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        }        
    }

    GetStudentsInputData (cohortId,pageOffset,searchquery,certtype="degree"){
        let studentsData;
        this.setState({ con: true });
        let cohortid = localStorage.getItem('selectedCohort');   
        if(cohortid==null || cohortid==undefined)
        {
            cohortid=this.Cohorts[0];
        }
        else
        {
            this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if(item.value==cohortid)
                {
                    this.cohortselectindex=i;
                }                
            
            }, this);
        }
        this.selectedCohort=cohortid;

        let selectedcerttypeid = localStorage.getItem('selectedCertType');        
        if(selectedcerttypeid==null || selectedcerttypeid==undefined)
        {
            selectedcerttypeid="degree";
            localStorage.setItem("selectedCertType","degree");
        }
        else
        {
            this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if(item.value==selectedcerttypeid)
                {
                this.certtypeselectindex=i;                        
                }                
            
            }, this);
        }
        this.selectedCertType=selectedcerttypeid;

        var Token = 'bearer ' +localStorage.getItem('Token');
        const headers = {
        'Authorization':  Token,                
        }
        let payload = {
            // key : "cohort",
            cohort: cohortId,
            certtype:certtype,
            pageOffset:pageOffset,
            maxitemsperpage:this.maxitemsperpage,
            searchValue:searchquery,
            step: "all"

        };
        axios.post(getStudentsInputData, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
        }).then(response => {             
            if(response.status == "200"){   
                this.setState({ con: false }); 
                if(response.data.Status==200)
                { 
                    // this.setState({alertData:{status:'Success',className: 'success', message: response.data.message}});
                    this.state.StudentsData = response.data.Data.mockData;
                    this.Cohorts = response.data.Data.cohortData;
                    try{
                        this.mainPageCount=response.data.Data.mockData[0].pagecount/this.maxitemsperpage;
                    }
                    catch(err){
                        this.mainPageCount=1;
                    }
                    
                    let cohortid = localStorage.getItem('selectedCohort');      
                    console.log(response.data.Data.mockData);
                    if(cohortid!==null && cohortid!==undefined && cohortid!=="")                
                    {
                        //console.log(cohortid+"dd");      
                        this.Cohorts.length > 0
                        && this.Cohorts.map((item, i) => {
                            if(item.value==cohortid)
                            {
                                this.cohortselectindex=i;
                                this.setState({displayCohort: [{label: item.label, value: item.value}]});
                            }                
                        
                        }, this);
                    }
                    else
                    {
                        if(this.Cohorts.length > 0)
                        {
                            console.log(this.Cohorts[0]["value"]) ;                        
                            localStorage.setItem('selectedCohort', this.Cohorts[0]["value"]);
                            this.selectedCohort=this.Cohorts[0]["value"]; 
                            this.setState({displayCohort: [{label: this.Cohorts[0].label, value: this.Cohorts[0].value}]});                       
                        }
                    }
                    if( response.data.step = 'all' )
                    { 
                        
                        studentsData = TreeState.create(response.data.Data.mockData);
                       
                        if(studentsData.data.length>0) studentsData = TreeState.expandAll(studentsData);
                        this.initCheckStatsinprogress(response.data.Data.mockData);
                    }
                    this.setState({StudentsData:studentsData});
                }
                else
                {
                    this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
                }
                this.setState({con:false});
            }
            else if (response.status == "400"){
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
            }
            else {
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'failed'}});
            }
            
        }).catch((error) => {
            this.setState({con:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: "Error"}});
        }); 
    }

    changeCertType = (e) => {
        let idx = e.target.selectedIndex;
        this.certtypeselectindex=idx;
        let value = e.target.options[idx].value;	      

        this.selectedCertType=value;        
        localStorage.setItem('selectedCertType', this.selectedCertType);
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        //this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        this.GetStudentsInputData(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }
    
    componentDidMount()
    {
        axios.get('https://jsonip.com/')
        .then(response => {
            console.log(response.data.ip);
            this.ipAddress = response.data.ip;
        })
        .catch(error => console.log(error));
        let cohortid = localStorage.getItem('selectedCohort');   
        if(cohortid==null || cohortid==undefined)
        {
            cohortid=this.Cohorts[0];
        }
        else
        {
            this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if(item.value==cohortid)
                {
                    this.cohortselectindex=i;
                }                
            
            }, this);
        }
        this.selectedCohort=cohortid;

        let selectedcerttypeid = localStorage.getItem('selectedCertType');        
        if(selectedcerttypeid==null || selectedcerttypeid==undefined)
        {
            selectedcerttypeid="degree";
            localStorage.setItem("selectedCertType","degree");
        }
        else
        {
            this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if(item.value==selectedcerttypeid)
                {
                this.certtypeselectindex=i;                        
                }                
            
            }, this);
        }
        this.selectedCertType=selectedcerttypeid;

        console.log(this.selectedCohort, this.selectedCertType);

        localStorage.setItem("selectedpageindex","0");  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;           
        // this.GetStudents(cohortid,this.mainPageOffset,this.state.searchValue,selectedcerttypeid);
        this.GetStudentsInputData(cohortid,this.mainPageOffset,this.state.searchValue,selectedcerttypeid);
        this.GetProfile();
    }

    GetProfile() {
        console.log("GetProfile")
        this.setState({ con: true })
        this.props.dispatch(GetProfileFetchData(getProfile));
    } 
    fetchApiData()
    {
        console.log("fetchApiData");
        //this.setState({ con: true });
        this.setState(prevState => ({
            dialogOpen: true
          }));
        let payload = {
            
        };               

        /*
        var Token = 'bearer ' +localStorage.getItem('Token');
        const headers = {
        'Authorization':  Token,                
        }
        axios.post(uploadstudentbyapi, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
        }).then(response => {             
            if(response.status == "200"){    
                if(response.data.Status==200)
                { 
                    this.setState({alertData:{status:'Success',className: 'success', message: response.data.message}});
                }
                else
                {
                    this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
                }
                this.setState({con:false});
            }
            else if (response.status == "400"){
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
            }
            else {
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'failed'}});
            }
            
        }).catch((error) => {
            this.setState({con:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: "Error"}});
        }); 
        */
    }
    componentDidUpdate(prevProps, prevState) {
    
        if ( prevProps.Students ) {
          let studentsData;
         
            this.setState({Students:prevProps.Students});
            if (prevProps.Students.Status === 200 )
            {
              
                this.Cohorts = prevProps.Students.Data.cohortData; 
                
                try
                {
                    this.mainPageCount=prevProps.Students.Data.mockData[0].pagecount/this.maxitemsperpage;
                    
                }
                catch(error)
                {
                    this.mainPageCount=1;
                }
                let cohortid = localStorage.getItem('selectedCohort');      
              
                if(cohortid!==null && cohortid!==undefined && cohortid!=="")                
                {
                    //console.log(cohortid+"dd");      
                    this.Cohorts.length > 0
                    && this.Cohorts.map((item, i) => {
                        if(item.value==cohortid)
                        {
                            this.cohortselectindex=i;
                            this.setState({displayCohort: [{label: item.label, value: item.value}]});
                        }                
                    
                    }, this);
                }
                else
                {
                    if(this.Cohorts.length > 0)
                    {
                        console.log(this.Cohorts[0]["value"]) ;                        
                        localStorage.setItem('selectedCohort', this.Cohorts[0]["value"]);
                        this.selectedCohort=this.Cohorts[0]["value"]; 
                        this.setState({displayCohort: [{label: this.Cohorts[0].label, value: this.Cohorts[0].value}]});                       
                    }
                }    

                     
                /// this.setState({Cohorts:prevProps.Students.Data.cohortData});
                if( prevProps.Students.step = 'all' )
                    { 
                       
                        studentsData = TreeState.create(prevProps.Students.Data.mockData);
                       
                        if(studentsData.data.length>0)
                                studentsData = TreeState.expandAll(studentsData);
                        this.initCheckStatsinprogress(prevProps.Students.Data.mockData);
                    }
            } else {
                studentsData = [];
                this.setState({alertData:{status:'Success',className: 'warning', message: prevProps.Students.message}})
            }


            this.setState({StudentsData:studentsData});
            this.setState({con:false});
            /*
            if (this.state.loaded) {
                this.setState({loaded: false});
            } else {
                this.setState({con:false});
                this.setState({loaded:true});
            }
            */
        }
        
        if ( prevProps.deleteStudent ) {
            this.setState({con:false});
            this.setState({copyemailaspkstatus: prevProps.copyemailaspkstatus});
            let alertData = {};
            alertData.status = prevProps.deleteStudent.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Success';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = 'Oops, Went something wrong'
            }
            this.setState({alertData: alertData});
        }
        
        
        if ( prevProps.copyemailaspkstatus ) {

            this.setState({con:false});
            this.setState({copyemailaspkstatus: prevProps.copyemailaspkstatus});
            let alertData = {};
            alertData.status = prevProps.copyemailaspkstatus.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Email is updated successfully as PK';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = 'Oops, Went something wrong'
            }
            this.setState({alertData: alertData});
        }
        if ( prevProps.reducerupdatestudentinformation) {
            
            this.setState({con:false}); 
            let alertData = {};
            alertData.status = prevProps.reducerupdatestudentinformation.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Student Informations are updated successfully';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = prevProps.reducerupdatestudentinformation.message;
            }
            this.setState({alertData: alertData});
           
        }

        if ( prevProps.InvitationStatus ) {

            this.setState({con:false});
            this.setState({InvitationStatus: prevProps.InvitationStatus});

            let alertData = {};
            if ( prevProps.InvitationStatus.flag == 'invite' )
            {

                alertData.status = prevProps.InvitationStatus.Status;

                if ( alertData.status === 200 ) {
                    alertData.className = 'success';
                    alertData.message = 'Sent invitation to selected students successfully';
                    
                    this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
                    
                } else {
                    alertData.className = 'danger';
                    alertData.message = 'Oops, Went something wrong'
                }
                this.setState({alertData: alertData});
            } else if ( prevProps.InvitationStatus.flag == 'preview') {
              let emailList = prevProps.InvitationStatus.PreviewMail;
              this.pageCount = emailList.length;
              this.setState({showModal:true, ModalContents: emailList });
            }
            else
            {
                alertData.status = prevProps.InvitationStatus.Status;
                alertData.className = 'danger';
                alertData.message = prevProps.InvitationStatus.message;
                this.setState({alertData: alertData});
                
            }
        }

        if ( prevProps.UploadStudents ) {
            this.setState({con:false});
            let alertData = {};
            alertData.status = prevProps.UploadStudents.Status;
            if ( prevProps.UploadStudents.Status === 200 ) {
                alertData.className = 'success';
                alertData.message = prevProps.UploadStudents.message;//'Upload students data successfully';
                this.GetStudents(this.selectedCohort,0,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                //alertData.message = 'Oops, Went something wrong'
                alertData.message = prevProps.UploadStudents.message;
            }
            this.setState({alertData: alertData});
        }

        if ( prevProps.PreviewStudentPhotoStatus ) {
            
            if(prevProps.PreviewStudentPhotoStatus.Status===200)
            {
                this.setState({con:false});                
                let photoContent = prevProps.PreviewStudentPhotoStatus.photoContent;
                let photoTitle = prevProps.PreviewStudentPhotoStatus.photoTitle;
                // this.pageCount = certList.length;
                this.setState({showModal:true, selectedphotoContent: photoContent, selectedPhotoTitle: photoTitle});
            }
            else
            {
                if(prevProps.PreviewStudentPhotoStatus.message=="Cannot read property 'displayHtml' of null" || prevProps.PreviewStudentPhotoStatus.message=="Unexpected end of JSON input")
                { 
                    this.setState({alertData:{status:'No available certificates',className: 'warning', message: 'There is no generated certificates'}}) ;
                }
                else
                {
                    this.setState({alertData:{status:'No available certificates',className: 'warning', message: prevProps.PreviewStudentPhotoStatus.message}}) ;
                }
                window.scrollTo(0,0); 
                this.setState({con:false});
                
            }
            
        }
        if (prevProps.Profile) {
            console.log("prevProps.Profile=",prevProps.Profile);
            if (prevProps.Profile.Status === 200) {                
                if(prevProps.Profile.Data.verificationitems!=null && prevProps.Profile.Data.verificationitems!=undefined && prevProps.Profile.Data.verificationitems!="")
                {
                    console.log(JSON.parse(prevProps.Profile.Data.verificationitems))
                    this.setState({verificationitems:JSON.parse(prevProps.Profile.Data.verificationitems)});
                }
                if(prevProps.Profile.Data.isapimode==false)
                {                    
                    this.setState({isapimode:false});
                }
                else
                {                          
                    this.setState({isapimode:true});    
                }
            }
            
        }

    }

    onFileChange(e)
    {
      this.files=e.target.files;
      if(this.files.length > 0) {
          let file = this.files[0];
          let name = file.name;
          this.setState({placeholder: name });
      }

    }

    onPhotoFileChange(e)
    {
        this.photofiles=e.target.files;
        if (this.selectedCohort == "" || this.selectedCohort == undefined){
            this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
        }
        else if(this.photofiles.length > 0) {        
            this.setState({con:true});
            const data = new FormData();
            for (let index=0; index < this.photofiles.length; index++){
                data.append('file' + index, this.photofiles[index]);
            }
            data.append('certtype', this.selectedCertType);
            data.append('cohortid', this.selectedCohort);
            // data.append('files', this.photofiles);
            var Token = 'bearer ' +localStorage.getItem('Token');
            const headers = {
            'Authorization':  Token,                
            }
            axios.post(UPLOADMULTIPHOTOS, data, { credentials: "same-origin", headers: headers
            }).then(res => { 
                console.log(res.data.message);
                this.setState({con:false});
                if(res.data.Status===200)
                {
                    this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                }
                else
                {
                    this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                }
            }).catch((err) => {
                console.log(err);
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file upload failed.'}});
            });                      
        }
    }

    checkenterkeyforsearch = (e) => {
        console.log(e);
        if (e.key == "Enter") {
            this.searchAction();
        }
    } 

    searchAction()
    {      
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;         
        //this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        this.GetStudentsInputData(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }
    readFile()
    {
        const reader = new FileReader();

        if(this.files.length>0)
        {
            this.setState({con:true});
            let file=this.files[0];
            let name=file.name;
            if(name.indexOf(".zip")!==-1 || name.toString().toLowerCase().indexOf(".pdf")!==-1)
            {
            //   alert("this is zip file");
                if (name.indexOf(" ") != -1){
                    this.setState({con:false});
                    this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file name has space string. Please remove space string from file name.'}});
                }
                else {
                    if (this.selectedCohort == "" || this.selectedCohort == undefined || this.selectedCohort == null){
                        this.setState({con:false});
                        this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
                    }
                    else{
                        const data = new FormData();
                        data.append('file', file);
                        data.append('certtype', localStorage.getItem("selectedCertType"));
                        data.append('cohortid', localStorage.getItem("selectedCohort"));
                        var Token = 'bearer ' +localStorage.getItem('Token');
                        const headers = {
                            'Authorization':  Token,                
                            }
                        axios.post(UPLOADPHOTOS, data, { credentials: "same-origin",headers: headers                    
                            }).then(res => { 
                                // console.log(res.data.message);
                                this.setState({con:false});
                                if(res.data.Status===200)
                                {
                                this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                                }
                                else
                                {
                                this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                                }
                            }).catch((err) => {
                                console.log(err);
                                this.setState({con:false});
                                this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file upload failed.'}});
                            });
                    }
                }              
            }
            else if(name.indexOf(".xlsx")!==-1)
            {
                if (name.toString().toLowerCase().indexOf("ljkuteacher") != -1){
                    if (name.indexOf(" ") != -1){
                        this.setState({con:false});
                        this.setState({alertData:{status:'empty',className: 'warning', message: 'Excel file name has space string. Please remove space string from file name.'}});
                    }
                    else {
                        if (this.selectedCohort == "" || this.selectedCohort == undefined || this.selectedCohort == null){
                            this.setState({con:false});
                            this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
                        }
                        else{
                            const data = new FormData();
                            data.append('file', file);
                            data.append('certtype', localStorage.getItem("selectedCertType"));
                            data.append('cohortid', localStorage.getItem("selectedCohort"));
                            var Token = 'bearer ' +localStorage.getItem('Token');
                            const headers = {
                                'Authorization':  Token,                
                                }
                            axios.post(UPLOADPHOTOS, data, { credentials: "same-origin",headers: headers                    
                                }).then(res => { 
                                    // console.log(res.data.message);
                                    this.setState({con:false});
                                    if(res.data.Status===200)
                                    {
                                    this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                                    }
                                    else
                                    {
                                    this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                                    }
                                }).catch((err) => {
                                    console.log(err);
                                    this.setState({con:false});
                                    this.setState({alertData:{status:'empty',className: 'warning', message: 'Excel file upload failed.'}});
                                });
                        }
                    }           
                }
                else reader.readAsBinaryString(file);
              //alert("this is xlsx file");
            }
            else
            {
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select correct file format.'}});
            }

        } else {
            this.setState({con:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select the file'}});
        }

        reader.onload = (evt) => {

        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary',cellDates: true,cellNF: false,cellText: false});

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(ws);
        var json_object = JSON.stringify(XL_row_object);
        const data = XLSX.utils.sheet_to_csv(ws, {header:1});


        let selectedcerttypeid = localStorage.getItem('selectedCertType');        
        if(selectedcerttypeid==null || selectedcerttypeid==undefined)
        {
            selectedcerttypeid="degree";
        }
        else
        {
            this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if(item.value==selectedcerttypeid)
                {
                this.certtypeselectindex=i;                        
                }                
            
            }, this);
        }
        this.selectedCertType=selectedcerttypeid;


        let payload = {  
            selectedcerttypeid: selectedcerttypeid,          
            value: json_object,
            ipaddress: this.ipAddress            
        };
       // this.props.dispatch(UploadStudentsFetchData(UPLOADSTUDENTS, data));
        this.props.dispatch(UploadStudentsFetchData(UPLOADSTUDENTS, JSON.stringify(payload)));
        };

    }

    //Send invitation to selected students
    sendInvitation() {
        
        try {
          let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {
                this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students'}})
                this.setState({con:false});
            } else {
                this.setState({alertData:{status:'empty',className: 'success', message: 'Please wait a few minutes.'}})
                this.props.dispatch(SendInvitation(sendInvitation, selectedItems,"invite",this.selectedCertType));
            }    
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
        
    }
    //Preview Invitation Email
    previewEmail() {
        try {
            this.setState({con:true});
            let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {            
                this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students'}})
                this.setState({con:false});
            }
            else
            {
                this.closeAlert();
                this.props.dispatch(SendInvitation(sendInvitation, selectedItems,"preview",this.selectedCertType));
            }    
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
    }

    previewStudentPhoto(coid,emid) {        
        this.setState({con:true});
        this.closeAlert();    
        let payload = {
            cohortid: coid,
            studentid: emid,
            certtype:localStorage.getItem("selectedCertType"),
        };        
        this.props.dispatch(PreviewStudentPhoto(previewstudentphoto, payload));        
    }    

    closeModal() {
      this.setState({showModal:false});
    }
    closeAlert() {
      let alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData})
    }

    handlePageClick = data => {
      let selected = data.selected;
      this.setState({currentOffset:selected});
    }
    handleMainPageClick(data){
      let selected = data.selected;      
      localStorage.setItem('selectedpageindex', selected*this.maxitemsperpage);
      this.mainPageOffset=selected*this.maxitemsperpage;
      this.initialMainPageIndex=selected;      
      this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);      
    }
    downloadTemplate = e =>
    {
        e.preventDefault(); 
        window.open(server_ip+"/uploadtemplate/template.xlsx");
    }
    closeUpdateInformation() {
        this.setState({showUpdateInformation:false});        
    }
    closeDeclineConfirmModal() {
        this.setState({showDeclineConfirmModal:false});        
    }
    showDeclineConfirmModal()
    {
        
        this.setState({showDeclineConfirmModal:true});        
    }
    updateInformation()
    {
        this.setState({con:true});
        this.closeAlert();
        this.closeUpdateInformation();
        let payload = {
            selectedCertType: localStorage.getItem("selectedCertType"),
            selectedcoid : this.state.selectedcoid,
            selectedstdid : this.state.selectedstdid,
            newemail : this.state.newemail,
            firstname : this.state.newfirstname,
            middlename : this.state.newmiddlename,
            lastname : this.state.newlastname,
        };
        this.props.dispatch(reduxUpdateInformation(updateinformationurl, JSON.stringify(payload)));
    }
    openEditModal(coid,emid,stdname,emailaddress,fathername,examyear,school,stdid,coursename,certtype,imagedata, totaldata) {
        
        //alert(coid);
        //alert(emid);
        // this.show=emailaddress;
        // this.newfirstname=firstname;
        // this.newmiddlename=middlename;
        // this.newlastname=lastname;
        //let key="competencyname";
        //console.log("totaldata=", totaldata[key]);
        this.setState({showUpdateInformation:true,showstudentname:stdname,showstudentid:stdid,showemail:emailaddress,showfathername:fathername,showcoursename:coursename,showexamyear:examyear,showschool:school,showcerttype:certtype,showimagedata: imagedata, totaldata:totaldata});        
    }
    onCloseRenderAPIDialog() {
        console.log("hihihihih");
        this.setState({dialogOpen: false});

    }
    renderApiDialog()
    {
        return(                
            <ApiDialog open={this.state.dialogOpen} onClose={()=>{ this.onCloseRenderAPIDialog() }}/>
        );
    }
    renderSelectForPageSize(){
        try {
            let selectedItems = 0;            
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        const idarr=this.checkStats["checkStats"][i].id.split("=");
                        if(idarr[1]!=="")
                        {
                            selectedItems++;
                        }
                    }
                }
            }
            let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'},{'label' : '300', 'value' : '300'},{'label' : '350', 'value' : '350'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'}];
            let options = Actions.length > 0
                && Actions.map((item, i) => {                    
                    if(item.value==this.maxitemsperpage)
                    {
                    return (
                            <option selected={"selected"} key={i} value={item.value}>{item.label}</option>
                        );
                    }
                    else
                    {
                    return (
                            <option key={i} value={item.value}>{item.label}</option>
                        );
                    }
                    
                }, this);
            
           
            return(                
                <div className="row" style={{"marginLeft":"0px","marginTop":"10px"}}>
                    <div >
                        <select  style={{"height":"30px","width":"65px"}} onChange={this.selectPageSize} >
                            {options}
                        </select>
                    </div>
                    <div  style={{"marginLeft":"10px","marginTop":"3px"}}>
                        Selected <b>{selectedItems}</b> items
                    </div>  
                </div>
            );
        } 
        catch (error)
        {            
            
        }
        
    }

    delete_student() {        
        this.setState({deleteStudentConfirmModal:true});
        window.scrollTo(0,0);
    }

    closeDeleteStudentConfirmModal() {   
        this.setState({deleteStudentConfirmModal:false});        
    }
    
    deleteStudentConfirm()
    {
        try {
            this.setState({con:true});
            let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {                        
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {            
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select correct items'}})
                this.setState({con:false});
            } 
            else
            {                
                let payload = {
                    selectedItems : selectedItems,
                    // cohortid: localStorage.getItem("selectedCohort"),
                    certtype: this.selectedCertType
                };
                this.setState({deleteStudentConfirmModal:false});
                this.props.dispatch(DeleteStudentsData(deletestudent, payload)); 
            }    
        } catch (error) {
            this.setState({deleteStudentConfirmModal:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
        // let payload = {
        // cohortid: localStorage.getItem("selectedCohort"),
        // certtype: this.selectedCertType
        // };
        // this.setState({deleteStudentConfirmModal:false});
        // this.props.dispatch(DeleteStudentsData(deletestudent, payload)); 
    }

    render() {
        
        let selectedpageindex = localStorage.getItem('selectedpageindex');        
        if(selectedpageindex==null || selectedpageindex==undefined) selectedpageindex=0;        
        this.mainPageOffset=selectedpageindex;           
        this.initialMainPageIndex=parseInt(selectedpageindex)/parseInt(this.maxitemsperpage);        
        
        const {placeholder} = this.state;
        var alertData = this.state.alertData;
        var currentOffset = this.state.currentOffset;
        var previewContents = this.state.ModalContents;
        var currentPreview = (previewContents[currentOffset]) ? previewContents[currentOffset].mailitem : '';
        
        let cohortList = this.Cohorts.length > 0
    	&& this.Cohorts.map((item, i) => {
            if(this.cohortselectindex==i)
                {
                    
                    return (
                        <option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option title={item.label} key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this);
        

        let certtypeList = this.CertType.length > 0
    	&& this.CertType.map((item, i) => {
            if(this.certtypeselectindex==i)
                {
                    
                    return (
                        <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this);



        return (
            <section style={{"padding":"10% 25px","width":"100%"}} className="project-tab">
            {this.state.con && (<Spinner></Spinner>)}
            { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
            
            <div className="row">
                <div className="col-xl-11 col-lg-12 col-md-12">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div style={{"marginTop":"10px", "display": "flex", "columnGap": "10px"}}>
                                <label style={{"marginTop":"8px","fontSize":"14px"}} >Batch</label>
                                <Select className="cohortDropdown" style={{'borderColor': 'rgb(206, 212, 218)', 'borderRadius': '4px', 'width': '400px'}} values={this.state.displayCohort} options={this.Cohorts} clearable={true} placeholder=""  onChange={values => this.setCohort(values)}/>
                    
                                {/*<Select className="col-md-6 cert-row-column"  options={this.Cohorts} onChange={(values) => this.changeCohorts(values)} />*/}
                                {/* <label style={{"marginTop":"8px","fontSize":"14px"}} >Batch</label>
                                <select id="cohortSelect" size={this.state.size} onFocus={()=>{this.setState({size: this.Cohorts.length==0?1:this.Cohorts.length})}} onBlur={()=>{this.setState({size: 1})}} onChange={(e)=>{e.target.blur()}}  style={{"padding":"7px 10px","zIndex": "100","position": "absolute","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"8px","fontSize":"12px"}} onChange={this.changeCohorts} >
                                        {cohortList}
                                </select> */}
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div style={{"marginTop":"10px", "display": "flex", "columnGap": "10px"}}>
                                <label style={{"marginTop":"7px","fontSize":"14px"}} >Certificate-Type</label>
                                {/*<Select className="col-md-6 cert-row-column"  options={this.Cohorts} onChange={(values) => this.changeCohorts(values)} />*/}
                                <select  style={{"padding":"7px 10px","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"8px","fontSize":"12px"}} onChange={this.changeCertType} >
                                        {certtypeList}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div style={{"marginTop":"10px"}}>
                                
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12">
                            <div style={{"marginTop":"10px"}}>
                                <table border="0" >           
                                <tbody>
                                    <tr> 
                                    <td >
                                    <input style={{"marginTop": "-1px","background":"white","height":"38px","border": "1px solid rgb(206, 212, 218)","borderBottomLeftRadius": ".25rem","borderTopLeftRadius": ".25rem","borderTopRightRadius": "0rem","borderBottomRightRadius": "0rem","textAlign":"left","paddingLeft":"5px","width":"100%"}} type="text" name="searchinput" id="searchinput" 
                                    onChange={e=>this.setState({searchValue:e.target.value})} onKeyDown={e=>this.checkenterkeyforsearch(e)} defaultValue={this.state.searchValue} />
                                    </td>
                                    <td style={{"textAlign":"left"}}>
                                    <button style={{"marginTop": "-6px","borderBottomLeftRadius": "0rem","borderTopLeftRadius": "0rem","height":"38px"}} className="input-group-text" onClick={()=>this.searchAction()}><i className="fa fa-search"></i></button>
                                    </td>
                                    
                                    </tr>
                                    </tbody>   
                                </table>
                            </div>
                        </div>                        
                    </div>
                </div>                
                <div className="col-xl-1 col-lg-12 col-md-12" style={{"textAlign":"right"}}>
                    <div style={{"marginTop":"10px"}}>
                        {/* <a style={{"color":"#0275d8","fontWeight":"1px"}} href="#" onClick={this.downloadTemplate}>Template file</a> */}
                    </div>
                </div>
            </div>
            
            
            { (this.state.StudentsData.data && this.state.StudentsData.data.length >0 ) ?
                        (<TreeTable style={{"margin-top":"10px"}} className="demo-tree-table1"
                                height="360"
                                headerHeight="40"

                                value={this.state.StudentsData}
                                onChange={this.handleOnChange}
                                >
                                <TreeTable.Column renderCell={this.renderIndexCellinprogress} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Name</span>} basis="350px" grow="0"/>
                                <TreeTable.Column renderCell={this.renderColumninprogress2} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>eMail</span>} />                                
                                <TreeTable.Column renderCell={this.renderColumninprogress3} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>PublicKey</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress4} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>SecondEmail</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress5} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>OneTimeCode</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress6} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Status</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress7} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>View</span>} />
                                {/* <TreeTable.Column renderCell={this.renderColumninprogress8} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Photo</span>} /> */}
                                </TreeTable>) : (<div style={{"marginLeft":"25px"}} className="row"><p>There is no data</p></div>)}

            
            {this.renderSelectForPageSize()}   
            <center>                 
                <div style={{"marginTop":"10px"}}>
                {/*}
                {this.selectedCertType!="openbadges"?(<button className="btn btn-info" onClick={()=>this.sendInvitation()}>Send Invitation</button>):""}
                {"     "}
                {this.selectedCertType!="openbadges"?(<button className="btn btn-info" onClick={()=>this.previewEmail()}>Preview Invitation email</button>):""}
                {"     "}
                {*/}
                {<button className="btn btn-info" onClick={()=>this.updatestudents("approve")}>Approve</button>}
                {"     "}                    
                {/* <input style={{display: 'none'}} multiple type="file" id="photofile" onChange={(e)=>this.onPhotoFileChange(e)} accept=".png, .jpg, .jpeg" />
                <label className="btn btn-info" for="photofile" style={{marginTop: '7px'}}>Upload Photo</label>
                {"     "} */}
                {<button className="btn btn-info" onClick={this.showDeclineConfirmModal}>Decline</button>}
                <div  style={{"float":"right"}}>
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.mainPageCount}
                        // initialPage={this.initialMainPageIndex}
                        forcePage={this.initialMainPageIndex}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={(e)=>this.handleMainPageClick(e)}
                        disableInitialCallback={true}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        />
                </div>
                </div>
            </center>
            
            <Modal size='lg' dialogClassName="updateinformation" show={this.state.showUpdateInformation} onHide={this.closeUpdateInformation} centered scrollable>
                <Modal.Header closeButton>
                <Modal.Title><p style={{"color":"#0275d8","fontSize":"20px"}}>Recipient Information</p></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>                        
                    <table border="0" style={{"width":"100%","margin": "auto"}}>
                    <tbody>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Student Name:</p>
                            </td>
                            <td >
                                <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="showstudentname" value={this.state.showstudentname} disabled/>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Student ID:</p>
                            </td>
                            <td >
                                <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="showstudentid" value={this.state.showstudentid} disabled/>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Email:</p>
                            </td>
                            <td >
                                <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="show" value={this.state.showemail} disabled/>
                            </td>
                        </tr>
                        {Object.entries(this.state.verificationitems).map(([key, value]) => (
                            <tr key={key}>
                                <td>
                                <p style={{ color: "black", fontSize: "16px" }}>{value}</p>
                                </td>
                                <td>
                                {this.state.totaldata && this.state.totaldata[key] ? ( // Check if corresponding value exists
                                    <input
                                    type="text"
                                    style={{
                                        border: "1px solid #ccc",
                                        width: "100%",
                                        height: "35px",
                                        textAlign: "left",
                                        padding: "10px",
                                    }}
                                    id={key}
                                    value={this.state.totaldata[key]} // Assuming you want to display the value
                                    disabled
                                    />
                                ) : (
                                    <input
                                    type="text"
                                    style={{
                                        border: "1px solid #ccc",
                                        width: "100%",
                                        height: "35px",
                                        textAlign: "left",
                                        padding: "10px",
                                    }}
                                    id={key}
                                    value="" // Assuming you want to display the value
                                    disabled
                                    />
                                )}
                                </td>
                            </tr>
                            ))}
                        
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Dcoument ID:</p>
                            </td>
                            <td >
                                {/* <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="show" value={this.state.showdocumentid} disabled/> */}
                            </td>
                        </tr>
                        <tr>
                            <td colspan={2}>
                                <embed src={this.state.showimagedata}  height="300px" />
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </Modal.Body>                        
                {/* <Modal.Footer>
                <span><Button onClick={this.updateInformation}>&nbsp;&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                <Button onClick={this.closeUpdateInformation}>Cancel</Button>
                </Modal.Footer> */}
            </Modal>

            <Modal size='lt' dialogClassName="declineConfirm" show={this.state.showDeclineConfirmModal} onHide={this.closeDeclineConfirmModal} centered scrollable>
                <Modal.Header closeButton>
                <Modal.Title><p style={{"color":"#0275d8","fontSize":"24px"}}>Alert</p></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>
                <p style={{"color":"black","fontSize":"16px"}}>                    
                </p>
                    <table border="0" style={{"width":"90%","margin": "auto"}}>
                        <tbody>                        
                            <tr>
                                <td>
                                    <p style={{"color":"black","fontSize":"16px"}}>Reason:</p>
                                </td>
                                <td>
                                    <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="declinereason" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>                        
                <Modal.Footer>
                <span><Button onClick={()=>this.updatestudents("decline")}>&nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                <Button onClick={this.closeDeclineConfirmModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            {/* {this.renderApiDialog()} */}
        </section>
    )}
     handleOnChange = (newValue) => {
           this.setState({ StudentsData: newValue });

        }
    renderIndexCellinprogress = (row) => {

      let checked = false;
            let idval=row.data.cohortid+"="+row.data.studentid;
           
            let parentidval=row.data.cohortid+"=";
            let parentchecked=false;
            if ( this.checkStats["checkStats"])
            {
                console.log(idval);
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                    const id = this.checkStats["checkStats"][i].id;

                    if(id==parentidval)
                    {
                        if(this.checkStats["checkStats"][i].checked==true)
                        {
                            parentchecked = true;

                        }
                        break;
                    }
                }
            }
            if(parentchecked==false && this.checkStats["checkStats"])
            {
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                    const childid = this.checkStats["checkStats"][i].id;
                    if(childid==idval)
                    {
                        if(this.checkStats["checkStats"][i].checked==true)
                        {
                            checked = true;
                        }
                        break;
                    }
                }
            }
            else
            {
               checked = true;
            }
            return (
                    <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px',"height":"40px"}} >
                        <span className="toggle-button-wrapper" >
                        {(row.metadata.hasChildren)
                            ? (
                                <span style={{"fontSize":"13px","height":"19px"}} className="toggle-button" onClick={row.toggleChildren}></span>
                            )
                            : ''
                        }
                        </span>
                        {/* {(row.data.is_child==true)?(<span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>):(<span><span style={{"verticalAlign":"top","lineHeight":"36px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e,row.data.cohortid,row.data.studentid)} /></span><span style={{"fontSize":"10px","color":"#040402"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>)} */}
                        {(row.data.is_child==true)
                        ? (
                            (row.data.field11=="krutidev010")
                                ? (
                                    <span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all", "fontFamily": "krutidev010regular"}}><span>{row.data.field1}</span></span></span>
                                )
                                : (
                                    <span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all"}}><span>{row.data.field1}</span></span></span>
                                )
                        )
                        : (
                            <span><span style={{"verticalAlign":"top","lineHeight":"36px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e,row.data.cohortid,row.data.studentid)} /></span><span style={{"fontSize":"10px","color":"#040402"}}><span>{row.data.field1}</span></span></span>
                        )}

                    </div>
                    );       

       }

    renderColumninprogress1 = (row) => {
        return (
            <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field1}</span>
        );
        }
    renderColumninprogress2 = (row) => {
    return (

            <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field2}</span>
    );
    }
    renderColumninprogress3 = (row) => {        
    return (
        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field3}</span>
    );
    }
    renderColumninprogress4 = (row) => {
    return (
        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.secondemailaddress}</span>
    );
    }
    renderColumninprogress5 = (row) => {
    return (
        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field4}</span>
    );
    }
    renderColumninprogress6 = (row) => {
        let statusConvert = {
            '1': 'Approved',
            '2': 'Declined',
            '5': 'PK received',
            '4': 'Certificate generated',
            '3': 'Certificate issued'
        }
    return (
        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{statusConvert[row.data.field5]}</span>
    );
    }
    renderColumninprogress7 = (row) => {
    if(row.data.status!=="")
    { 
        return (
            <div style={{"marginBottom":"18px"}}>
                <span style={{"verticalAlign": "text-top", "fontSize": "14px","cursor": "pointer"}} onClick={(e) => this.openEditModal(row.data.cohortid,row.data.studentid,row.data.field1,row.data.field2,row.data.field6,row.data.field7,row.data.field8,row.data.field9,row.data.field10,row.data.field11,row.data.field12,row.data.field13)}>
                    View
                </span>
            </div>
        );
    }
    
    }
    // renderColumninprogress8 = (row) => {
    // if (row.data.field10 == "Yes")
    //     return (
    //         <div style={{"marginBottom":"18px"}}>
    //             <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field10}</span>{" "}
    //             <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} onClick={(e) => this.previewStudentPhoto(row.data.cohortid,row.data.studentid)}>
    //             <img src="https://www.certonce.com/images/preview.png" style={{"height":"20px"}} ></img></span>
    //         </div>
    //     );
    // else if(row.data.field10 == "No")
    //     return (
    //         <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field10}</span>
    //     );
    // }
    toggleCheckboxChangeChildren = (e,cohortid,studentid) => {
        let idval=cohortid+"="+studentid;
        for (let i = 0; i < this.checkStats["checkStats"].length; i++) 
        {
            const id = this.checkStats["checkStats"][i].id;

            if(id==idval)
            {
                if(e.target.checked==true)
                {
                    this.checkStats["checkStats"][i].checked = true;
                }
                else
                {
                    this.checkStats["checkStats"][i].checked = false;
                    let parentidval=cohortid+"=";
                    for (let ii = 0; ii < this.checkStats["checkStats"].length; ii++) {
                        const id = this.checkStats["checkStats"][ii].id;

                        if(id==parentidval)
                        {
                            this.checkStats["checkStats"][ii].checked = false;
                        }
                    }
                }
                break;
            }
        }
        
        let checkchageval=this.state.checkchange;
        this.setState({checkchange:!checkchageval});
    }
    toggleCheckboxChangeParent = (e,cohortid,studentid) => {
        let idval=cohortid+"="+studentid;
        for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
        const id = this.checkStats["checkStats"][i].id;
        const idarr=id.split("=");
        if(idarr[0]==cohortid)
        {
            if(e.target.checked==true)
            {
                this.checkStats["checkStats"][i].checked = true;
            }
            else
            {
                this.checkStats["checkStats"][i].checked = false;
            }
        }
        }
        let checkchageval=this.state.checkchange;
        this.setState({checkchange:!checkchageval});
    }
}
function mapStateToProps(state, actions) {
    
    console.log(state);
    if (state.fetchStudentsData && state.fetchStudentsData.Data && state.fetchStudentsData.Data.Status) {
        return { Students: state.fetchStudentsData.Data}
    } 
    else if (state.DeleteStudentsData && state.DeleteStudentsData.Data && state.DeleteStudentsData.Data.Status) {
        return { deleteStudent: state.DeleteStudentsData.Data }
    } 
    else if(state.fetchSendInvitation && state.fetchSendInvitation.Data && state.fetchSendInvitation.Data.Status) {
        return { InvitationStatus: state.fetchSendInvitation.Data}
    }
    else if(state.copyemailaspk && state.copyemailaspk.Data && state.copyemailaspk.Data.Status) {
        return { copyemailaspkstatus: state.copyemailaspk.Data}
    }
    else if(state.reducerupdatestudentinformation && state.reducerupdatestudentinformation.Data && state.reducerupdatestudentinformation.Data.Status) {
        return { reducerupdatestudentinformation: state.reducerupdatestudentinformation.Data}
    }
    else if(state.fetchUploadStudents && state.fetchUploadStudents.Data && state.fetchUploadStudents.Data.Status) {
        return { UploadStudents: state.fetchUploadStudents.Data}
    }
    else if (state.PreviewStudentPhoto && state.PreviewStudentPhoto.Data && state.PreviewStudentPhoto.Data.Status) {
        return { PreviewStudentPhotoStatus: state.PreviewStudentPhoto.Data }
    }
    else if (state.fetchProfileData && state.fetchProfileData.Data && state.fetchProfileData.Data.Status) {
        return { Profile: state.fetchProfileData.Data }
    }
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(VerificationComponent));

// export default MyProfileView;